import React from 'react';
import styled from 'styled-components';

import {
  Title1, Title2 as Title2v3, Title3, Title4, Button as ButtonV3,
} from './v3/styledComponents';
import FounderCard from './FounderCard';
import about1 from '../../assets/about/aboutTilli.jpg';
import about2 from '../../assets/about/working-couturier.jpg';
import needle from '../../assets/about/needle.png';
import leather from '../../assets/about/leather.png';
import reel from '../../assets/about/reel.png';
import beryl from '../../assets/about/beryl.jpg';
import antoinette from '../../assets/about/antoinette.jpg';
import benjamin from '../../assets/about/benjamin.jpg';
import dashedCurve from '../../assets/about/dashed-curve.png';
import CTALink from '../CTALink';

const Container = styled.div`
  padding: ${({ isTop }) => isTop ? 0 : 20}px 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.white ? ({ theme }) => theme.colors.white : ({ theme }) => theme.colors.lightGrey};
  ${({ hasCurve }) => hasCurve && `
  background: center no-repeat;
  background-size: 100%;
  background-image: url(${dashedCurve});
  `}
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding:${({ noPaddingOnMobile }) => noPaddingOnMobile ? '0 0 70px' : '0px 18px'};
  }
`;

const CouturierContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  margin: 40px 0 20px;
`;

const Column = styled.div`
  flex: 1;
  margin: ${({ isTop }) => isTop ? 0 : 40}px 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: ${({ isCentered }) => isCentered ? 'center' : 'flex-start'};
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    max-width: 100%;
  }
`;

const ColumnCouturier = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 9% 0 75px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 40px 18px;
  }
`;

const P = styled.p`
  color: ${({ theme }) => theme.colors.navy};
  ${({ center }) => center ? 'text-align: center;' : 'text-align: left;'}
  ${({ narrow }) => narrow ? `max-width: ${narrow}px;` : null}
  line-height: 27px;
  margin: 15px 0;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const PIdea = styled.p`
  font-family: Libre Baskerville;
  color: ${({ theme }) => theme.colors.navy};
  line-height: 45px;
  font-size: 30px;
  text-align: center;
  padding: 0px 50px 40px;
  margin: 0;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    line-height: 30px;
    font-size: 20px;
    padding: 0 0 30px;
  }
`;

const Italic = styled.span`
  color: ${({ theme }) => theme.colors.green};
  font-style: italic;
`;

const Title2 = styled(Title2v3)`
@media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
  font-size: 20px;
}
`;

const Row = styled.div`
  display: flex;
  align-items: ${({ flexStart }) => flexStart ? 'flex-start' : 'center'};
  justify-content: space-between;
  ${({ columnOnMobile, theme }) => columnOnMobile && `
  @media (max-width: ${theme.mobileThresholdPixels}) {
    flex-direction: ${columnOnMobile};
    }
  `}
  ${({ flexStart, theme }) => flexStart && `
  @media (max-width: ${theme.mobileThresholdPixels}) {
    overflow-x: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    }
  `}
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
  padding: 40px 0;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    padding 30px 0 20px;
  }
`;

const Icon = styled.img`
  height: 130px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    height: 69px;
  }
`;

const ImgContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 500px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    height: auto;
    width: 100%;
  }
`;

const Img = styled.img`
  object-fit: contain;
  min-width: 45%;
  max-width: 100%;
`;
const ImgIdea = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  object-fit: cover;
  height: 670px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    position : relative;
    height: auto;
    width: 100%;
  }
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.navy};
  height: 144px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    height: 104px;
  }
`;

const HorizontalLine = styled.div`
  position: absolute;
  top: 15px;
  left: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.navy};
  width: 55px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    border-top: 0px;
    margin-top: 0;
  }
`;

const TextLink = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  margin: 12px 16px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    margin: 0;
  }
`;

const Button = styled(ButtonV3)`
  margin-top: 25px;
`;

const renderIdea = () => (
  <Row columnOnMobile="column-reverse">
    <Column>
      <Title3>Tilli, d’où vient l’idée ?</Title3>
      <P>
        Beryl, la co-fondatrice de Tilli, avait besoin de
        retoucher une robe pour un événement. À première vue,
        elle n’avait qu’une solution possible : la retoucherie
        en bas de chez elle qui n’était pas vraiment accueillante,
        ni gage de qualité.
      </P>
      <P>
        Elle en a donc parlé un peu autour d’elle pour
        trouver une autre solution. Une personne de son
        entourage lui a recommandé une couturière qui
        s’est déplacée à son domicile exceptionnellement.
        Ce moment passé avec cette couturière a vraiment
        été une expérience riche d’un échange humain et
        d’une expertise rassurante.
      </P>
    </Column>
    <ImgContainer>
      <ImgIdea src={about1} alt="Une retoucherie à Paris pour cintrer une jupe" />
    </ImgContainer>
  </Row>
);

const renderGoal = () => (
  <>
    <IconsContainer>
      <Icon src={needle} alt="" />
      <Icon src={leather} alt="" />
      <Icon src={reel} alt="" />
    </IconsContainer>
    <Column isCentered>
      <PIdea>
        <Italic>Beryl</Italic>
        , enchantée par la magie de ce moment, a créé
        <Italic> Tilli </Italic>
        dans le but de rendre l’accès à ce service
        plus simple et plus rapide
      </PIdea>
      <VerticalLine />
    </Column>
  </>
);

const renderTeam = () => (
  <div>
    <Column isCentered>
      <Title4>Les fondateurs</Title4>
      <Title2>Qui sont-ils ?</Title2>
    </Column>
    <Row flexStart>
      <FounderCard
        title="Beryl"
        subtitle="Co-fondatrice & CEO"
        src={beryl}
        link="https://www.linkedin.com/in/beryl-de-labouchere-22001a109/"
        alt="Retoucherie parisienne"
        paragraphe="Débordante de nouveaux projets et de nouvelles
        signatures, elle influe une énergie qu’il faut savoir suivre…"
      />
      <FounderCard
        title="Antoinette"
        subtitle="Co-fondatrice & COO"
        src={antoinette}
        link="https://www.linkedin.com/in/antoinette-fine-5a362940/"
        alt="Machine à coudre pour faire un ourlet à un pantalon"
        paragraphe="Un peu psycho des chiffres et du suivi des commandes,
        son rire contraste. Elle fait tourner la machine !"
      />
      <FounderCard
        title="Benjamin"
        subtitle="Co-fondateur & CTO"
        src={benjamin}
        link="https://www.linkedin.com/in/benjmichel/"
        alt="Un retoucheur à Paris qui cintre une pantalon"
        paragraphe="Il calme le tempo dans les bureaux par un doux son de
        ligne de code sur son clavier au même rythme qu’une machine à coudre."
      />
    </Row>
  </div>
);

const renderCouturier = () => (
  <Row columnOnMobile="column">
    <Img src={about2} alt="Une retoucherie à Paris pour cintrer une jupe" loading="lazy" />
    <ColumnCouturier>
      <HorizontalLine />
      <Title3>Un tournant pour le métier de couturier</Title3>
      <P>
        Tilli, c’est le premier service de couturiers à domicile.
        Et depuis le lancement de nos services, nous n’avons cessé de
        réinventer le métier d’artisan.
      </P>
      <P>
        Un vent de nouveauté introduit le métier d’artisan à l’ère
        numérique et de la mode durable. Au fil des années, notre équipe
        a développé de nombreux dispositifs pour mettre le métier d’artisan
        au devant de la scène, en passant par une application de gestion
        jusqu’à ouvrir une retoucherie 2.0.
      </P>
    </ColumnCouturier>
  </Row>
);

const renderMission = () => (
  <Column isCentered>
    <Title4>Notre mission</Title4>
    <Title2>Bichonner nos clients</Title2>
    <P narrow="600" center>
      Tilli sera votre parfait allié pour retoucher, réajuster ou
      transformer votre garde-robe, le tout en vivant une expérience
      personnalisée et humainement riche ! La cerise sur le gâteau ? Vous vous
      <b> libérez du temps</b>
      <span>, et vous </span>
      <b>consommez vos vêtements différemment </b>
      de façon plus durable.
    </P>
    <CTALink fromInteraction="About">
      <Button navy>
        <TextLink>Réserver un couturier</TextLink>
      </Button>
    </CTALink>
  </Column>
);

const About = () => (
  <div>
    <Container isTop white>
      <Column isTop isCentered>
        <Title1>L’histoire de Tilli</Title1>
        <Row>
          <P narrow="800" center>
            Chez Tilli, nous partageons tous un objectif commun : celui de remettre au goût
            du jour le métier de couturier en le digitalisant. Le couturier échange directement
            avec le client pour ne pas perdre en qualité et laisser place à une expérience unique !
          </P>
        </Row>
      </Column>
    </Container>
    <Container white>
      {renderIdea()}
    </Container>
    <Container>
      {renderGoal()}
    </Container>
    <Container white hasCurve noPaddingOnMobile>
      {renderTeam()}
    </Container>
    <CouturierContainer>
      {renderCouturier()}
    </CouturierContainer>
    <Container white>
      {renderMission()}
    </Container>
  </div>
);

export default About;
