import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import linkedIn from '../../assets/about/linkedIn.png';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    min-width: 70%;
    padding: 0 28px;
  }
`;

const ImgContainer = styled.div`
  width: 270px;
  height: 304px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 100%;
    height: auto;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Icon = styled.img`
  width: 35px;
  position: absolute;
  bottom: -17.5px;
  right: 24px;
  object-fit: cover;
  filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.25));
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 30px;
    bottom: -15px;
  }
`;

const Title = styled.h2`
  font-family: Libre Baskerville;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.colors.navy};
  margin: 35px 0 5px 0;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 18px;
  }
`;

const Subtitle = styled.h3`
  font-family: Libre Baskerville;
  font-weight: 400;
  font-size: 16px;
  font-style: italic;
  color: ${({ theme }) => theme.colors.green};
  margin: 0;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const P = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.navy};
  padding: 10px 4px;
  margin: 0;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 21px;
    padding: 10px 0;
  }

`;

const FounderCard = ({
  title, subtitle, link, src, alt, paragraphe,
}) => (
  <Card>
    <ImgContainer>
      <Img src={src} alt={alt} />
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Icon src={linkedIn} alt="" />
      </a>
    </ImgContainer>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <P>{paragraphe}</P>
  </Card>
);

FounderCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  paragraphe: PropTypes.string,
  link: PropTypes.string,
};

FounderCard.defaultProps = {
  title: '',
  subtitle: '',
  alt: '',
  paragraphe: '',
  link: '',
};

export default FounderCard;
