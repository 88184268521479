import React from 'react';
import { Container, Content } from '../../components/home/homeStyledComponents';
import MenuBar from '../../components/home/MenuBar';
import About from '../../components/home/About';
import InstaFeed from '../../components/home/InstaFeed';
import Footer from '../../components/home/v3/Footer';
import Layout from '../../layouts/index';

class AboutPage extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    return (
      <Layout routeSlug="AboutPage">
        <Container>
          <Content>
            <MenuBar />
            <About />
            <InstaFeed />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default AboutPage;
